import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";

@Directive({
  selector: "[kprDateFormat]",
})
export class DateFormatDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener("input", ["$event.target.value"])
  onInput(value: string): void {
    if (value) {
      const formattedValue = this.formatDate(value);

      this.renderer.setProperty(this.el.nativeElement, "value", formattedValue);
    }
  }

  private formatDate(value: string): string {
    // Remove all non-numeric characters
    const numericValue = value.replace(/\D/g, "");

    let formattedValue = numericValue;

    // Add slashes to format MM/DD/YYYY
    if (numericValue.length > 2) {
      formattedValue = numericValue.slice(0, 2) + "/" + numericValue.slice(2);
    }
    if (numericValue.length > 4) {
      formattedValue =
        formattedValue.slice(0, 5) + "/" + numericValue.slice(4, 8);
    }

    // Trim to MM/DD/YYYY length
    return formattedValue.slice(0, 10);
  }
}
