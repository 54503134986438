<div
  id="CavitySnapshotChartRoot"
  [style.width]="hostWidth"
  [style.height]="hostWidth"
>
  <koa-risk-chart
    [level]="chartLevel"
    [riskScore]="chartScore"
    [reversed]="reversed"
  ></koa-risk-chart>
</div>
