import { ImageResponse, ImageId, ImageLike } from "./image.model";
import { ToothNumber } from "@kells/interfaces/tooth";
import { KellsPatient, SubscriptionPlan } from "@kells/interfaces/patient";

/** The key under which the selected patient ID will be stored in local storage. */
export const LC_SELECTED_PATIENT_KEY = "selected-patient";

/**
 * Generic Session Indexed interface.
 *
 * Describes key-value pairs in which the key is a session date string,
 * and the value describes an image.
 */
export interface SessionIndexed<T extends ImageLike> {
  [sessionDate: string]: T[];
}

/**
 * Generic interface for defining a tooth number indexed object.
 *
 * Describes an object where the keys are tooth numbers, and the values are
 * image arrays.
 */
export type ToothNumberIndexed<G extends ImageLike> = {
  [toothNumber in ToothNumber]: G[];
};

export const SESSION_DATE_FORMAT = "yyyy-M-d";

export interface PatientDataResponse {
  _id: string;
  dob: string;
  first_name: string;
  last_name: string;
  office_id: string;
  email?: string;
  gender?: string;
  images?: SessionIndexed<ImageResponse>;
  insurance_provider: string | null;
  insurance_status: string | null;
  has_insurance?: boolean;
  consent_sms?: boolean;
  phone?: string;
  phone_country_code?: string;
  is_onboarded: boolean;
  is_photo_scan_onboarded: boolean;
  organizations?: string[];
  address?: {
    street1: string;
    city: string;
    state: string;
    zipcode: string;
  };
  subscription_plan: SubscriptionPlan;
}

export interface RawPatient extends PatientDataResponse {
  age: number;
  patient_id: number;
}

export interface Patient extends KellsPatient {
  predicting: boolean;
  images?: SessionIndexed<ImageId>;
}
