<ng-container *ngIf="isDataReady$ | async; else loadingContentFallback">
  <ng-container *ngIf="isDataReady$ | async; else loadingContentFallback">
    <div class="treatment-table">
      <ng-container *ngLet="treatmentTableEntries$ | async as elements">
        <ul role="list" class="actions-table px-0 desktop-view">
          <li class="py-5 grid grid-cols-12 border-b treatment-header-row">
            <ng-container *ngFor="let column of displayedColumns">
              <div class="heading" [ngClass]="columnSpanWidthClassName">
                <h4 class="treatment-label grey-label">{{ column }}</h4>
              </div>
            </ng-container>
          </li>
          <ng-container *ngFor="let element of elements">
            <li
              class="py-5 grid grid-cols-12 items-center border-b border-initial-400 treatment-item-row"
            >
              <div
                class="item-cell flex flex-col justify-center"
                *ngIf="
                  isColumnVisibile(TREATMENT_PLAN_TABLE_COLUMN_NAMES.Tooth)
                "
                [ngClass]="columnSpanWidthClassName"
              >
                <p
                  class="treatment-item-value ft-medium fw-500 grey-label"
                  [attr.style.backgroundColor]="element.urgencyFill"
                >
                  <span class="p-0">
                    {{ getToothName(element.toothData.tooth) }}</span
                  >
                </p>
                <p
                  class="treatment-item-value-small ft-small fw-500 grey-label"
                >
                  {{
                    getToothDescription(
                      element.toothData.tooth,
                      element.toothLocation
                    )
                  }}
                </p>
              </div>

              <div
                class="item-cell"
                [ngClass]="columnSpanWidthClassName"
                *ngIf="
                  isColumnVisibile(
                    TREATMENT_PLAN_TABLE_COLUMN_NAMES.TreatmentPlan
                  )
                "
              >
                <div class="treatment-item-value">
                  <span class="ft-medium fw-500 grey-label">
                    {{ element.treatmentPlan }}
                  </span>

                  <button
                    class="video-button"
                    *ngIf="hasVideo(element.treatmentPlan)"
                    (click)="openDialog(element.treatmentPlan)"
                  >
                    <i class="fa-solid fa-play"></i>
                    <span>Play video</span>
                  </button>
                </div>
              </div>

              <div
                class="item-cell"
                [ngClass]="columnSpanWidthClassName"
                *ngIf="
                  isColumnVisibile(
                    TREATMENT_PLAN_TABLE_COLUMN_NAMES.CostEstimates
                  )
                "
              >
                <p class="treatment-item-value ft-medium fw-500 grey-label">
                  {{ element.costEstimates }}
                </p>
              </div>

              <div
                class="item-cell"
                [ngClass]="columnSpanWidthClassName"
                *ngIf="
                  isColumnVisibile(
                    TREATMENT_PLAN_TABLE_COLUMN_NAMES.PriorityDesc
                  )
                "
              >
                <p class="treatment-item-value priority-action">
                  <ng-container *ngIf="element.priority === 2">
                    <div class="priority-urgent p-2 ft-medium fw-500">High</div>
                  </ng-container>
                  <ng-container *ngIf="element.priority === 1">
                    <div class="priority-medium p-2 ft-medium fw-500">
                      Medium
                    </div>
                  </ng-container>

                  <ng-container *ngIf="element.priority === 0">
                    <div class="priority-low p-2 ft-medium fw-500">
                      Low
                      <!-- {{element.priorityDesc}} {{element.priority}} -->
                    </div>
                  </ng-container>
                </p>
              </div>
            </li>
          </ng-container>
        </ul>
        <ul role="list" class="actions-table px-0 mobile-view">
          <ng-container *ngFor="let element of elements">
            <li
              class="py-5 grid grid-cols-12 border-b border-initial-400 treatment-item-row"
            >
              <div
                class="col-span-6 item-cell flex flex-col justify-center my-2"
              >
                <h4 class="treatment-label grey-label my-2">TOOTH LOCATION</h4>
                <p
                  class="treatment-item-value ft-medium fw-500 grey-label"
                  [attr.style.backgroundColor]="element.urgencyFill"
                >
                  <span class="p-0">{{
                    getToothName(element.toothData.tooth)
                  }}</span>
                </p>
                <p
                  class="treatment-item-value-small ft-small fw-500 grey-label"
                >
                  {{
                    getToothDescription(
                      element.toothData.tooth,
                      element.toothLocation
                    )
                  }}
                </p>
              </div>

              <div class="col-span-6 item-cell my-2">
                <h4 class="treatment-label grey-label my-2">ACTION</h4>
                <div class="treatment-item-value ft-medium fw-500 grey-label">
                  <div>
                    <div>
                      {{ element.treatmentPlan }}
                    </div>

                    <button
                      class="video-button"
                      *ngIf="hasVideo(element.treatmentPlan)"
                      (click)="openDialog(element.treatmentPlan)"
                    >
                      <i class="fa-solid fa-play"></i>
                      <span>Play video</span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-span-6 item-cell my-2">
                <h4 class="treatment-label grey-label my-2">COST ESTIMATE</h4>
                <p class="treatment-item-value ft-medium fw-500 grey-label">
                  {{ element.costEstimates }}
                </p>
              </div>

              <div class="col-span-6 item-cell my-2">
                <h4 class="treatment-label grey-label my-2">PRIORITY</h4>
                <p class="treatment-item-value priority-action">
                  <ng-container *ngIf="element.priority === 2">
                    <div class="priority-urgent p-2 ft-medium fw-500">High</div>
                  </ng-container>
                  <ng-container *ngIf="element.priority === 1">
                    <div class="priority-medium p-2 ft-medium fw-500">
                      Medium
                    </div>
                  </ng-container>

                  <ng-container *ngIf="element.priority === 0">
                    <div class="priority-low p-2 ft-medium fw-500">Low</div>
                  </ng-container>
                </p>
              </div>
            </li>
          </ng-container>
        </ul>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #loadingContentFallback>
    <p class="kpr-data-label">
      <i class="fa fas fa-circle-notch fa-heartbeat"></i> Loading
    </p>
  </ng-template>

  <ng-template #noContentFallback>
    <span class="fallback-message"> Not available. </span>
  </ng-template>
</ng-container>
