<div class="findings-filter" *ngIf="(findingsFilterItems$ | async)?.length">
  <i
    *ngIf="!(!isPrevSlideVisible && !isNextFilterSlideVisible)"
    [ngClass]="{ disabled: !isPrevSlideVisible }"
    (click)="prevFilterSlide()"
    class="findings-filter-prev fa-solid fa-chevron-left mr-3"
  ></i>
  <div
    class="filter-item-swiper-container"
    [ngClass]="{
      'filter-swiper-container-left-shadow': isPrevSlideVisible,
      'filter-swiper-container-right-shadow': isNextFilterSlideVisible
    }"
  >
    <swiper-container
      #swiper
      class="findings-filter-swiper"
      slides-per-view="auto"
      [spaceBetween]="'16px'"
    >
      <swiper-slide
        class="filter-item"
        [ngClass]="{
          selected: selectedFindingsFilterType === findingsType?.type
        }"
        (click)="toggleFindingsType(findingsType.type)"
        *ngFor="let findingsType of findingsFilterItems$ | async"
      >
        <span>
          {{ findingsType.name }}
        </span>
      </swiper-slide>
    </swiper-container>
  </div>

  <i
    *ngIf="!(!isPrevSlideVisible && !isNextFilterSlideVisible)"
    [ngClass]="{
      disabled: !isNextFilterSlideVisible
    }"
    (click)="nextFilterSlide()"
    class="findings-filter-next fa-solid fa-chevron-right mr-3"
  ></i>
</div>
