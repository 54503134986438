import { SubscriptionPlan } from "../models/subscription-plan.model";

export enum PatientFeatues {
  AI_Dental_Scan = "Virtual Checkup (AI Dental Scan)",
  Treatment_Verification = "Treatment Verification (second opinion)",
  Emergency_Consultation = "Emergency Consultation (dentist consultation)",
  Digital_Oral_Health_Report = "Digital Oral Health Report",
  Treatment_price_estimate = "Treatment price estimate",
  Product_Recommendation = "Product Recommendation",
  Find_Dentist = "Find a dentist (self-serve via zocdoc)",
}

export const PATIENT_FEATURES_BY_PLAN: Record<
  PatientFeatues,
  SubscriptionPlan
> = {
  [PatientFeatues.AI_Dental_Scan]: SubscriptionPlan.Premium,
  [PatientFeatues.Treatment_Verification]: SubscriptionPlan.Premium,
  [PatientFeatues.Emergency_Consultation]: SubscriptionPlan.Premium,
  [PatientFeatues.Digital_Oral_Health_Report]: SubscriptionPlan.Basic,
  [PatientFeatues.Treatment_price_estimate]: SubscriptionPlan.Premium,
  [PatientFeatues.Product_Recommendation]: SubscriptionPlan.Basic,
  [PatientFeatues.Find_Dentist]: SubscriptionPlan.Basic,
};
