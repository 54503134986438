<ng-container *ngLet="(images$ | async).values() as images">
  <div class="caries-xray-list">
    <div class="caries-xray-list-inner">
      <ng-container
        *ngFor="
          let findingsByImage of images;
          let i = index;
          trackBy: trackImageIdentityByUrl
        "
      >
        <div class="caries-xray-thumb">
          <koa-xray-image
            [index]="i"
            [filterTooth]="filterTooth"
            [image]="findingsByImage.image"
            [findingsByImage]="findingsByImage"
            [interactive]="true"
            [showGumInflammation]="true"
            [showMissingTooth]="true"
            [showGumRecession]="true"
            [showFracture]="true"
            [showPlaque]="true"
            [showBoneloss]="true"
            [showCaries]="true"
            [showFindings]="true"
            [interactive]="true"
            [hideIfNoFinings]="true"
            [showFindingDescription]="showFindingDescription"
            [totalCount]="imagesLength$ | async"
            (onXrayOpen)="xrayOpenEvent.emit()"
          ></koa-xray-image>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
