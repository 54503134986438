<section class="flex flex-col align-center relative">
  <h1
    class="kpr-font-label text-bold text-base text-initialcontrast-500 right-0 pb-3 -top-6"
  >
    {{ title }}
  </h1>

  <div
    class="w-full h-full overflow-hidden xray-panel"
    flex-space-2
    style="overflow-y: hidden"
  >
    <koa-xray-image
      #xray
      [image]="image"
      [filterTooth]="findingsByTooth"
      [findingsByImage]="findingsByImage"
      [interactive]="false"
      [showCaries]="showCaries"
      [showBoneloss]="showBoneloss"
      [showPlaque]="showPlaque"
      [showFracture]="showFracture"
      [showGumRecession]="showGumRecession"
      [showGumInflammation]="showGumInflammation"
      [showMissingTooth]="showMissingTooth"
      [showFindingDescription]="showFindingDescription"
    ></koa-xray-image>
    <!-- <div style="white-space: pre;">{{tooth | json}}</div> -->
  </div>
  <div class="close-button">
    <button
      mat-icon-button
      class="mat-button mat-icon-button transition-color"
      (click)="closeDialog()"
    >
      <i class="fa fas fa-close"></i>
    </button>
  </div>
</section>
