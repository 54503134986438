export enum OrganizationNames {
  Kells = "kells",
  LibertyDental = "liberty dental",
  KareMobile = "kare mobile",
  Aetna = "aetna",
  Villagecare = "villagecare",
  Teledentists = "teledentists",
  Beanstalk = "beanstalk",
  Lantnerdds = "lantnerdds",
  AYG = "ayg",
  DentalSavers = "dentalsavers",
}
