import { Injectable } from "@angular/core";

declare global {
  interface Window {
    voiceflow: {
      chat: {
        load: (args: any) => Promise<void>;
        open: () => void;
        close: () => void;
        show: () => void;
        hide: () => void;
        destroy: () => void;
        proactive: {
          clear: () => void;
          push: (...messages: ProactiveMessage[]) => void;
        };
      };
    };
  }
}

export type ProactiveMessage = {
  type: string;
  payload: {
    message: string;
  };
};

type UserInfo = {
  userID?: string;
  first_name: string;
  last_name: string;
};

type VoiceFlowConfig = {
  messages?: ProactiveMessage[];
  render?: {
    mode: "embedded";
    target: HTMLElement | null;
  };
  autostart?: boolean;
  assistant?: {
    stylesheet?: string;
  };
  defaultHidden?: boolean;
};

@Injectable({
  providedIn: "root",
})
export class VoiceflowService {
  private isVoiceflowScriptLoaded = false;
  private isVoiceflowChatLoaded = false;
  private isVoiceFlowLoading = false;
  private initializationPromise: Promise<void> | null = null;

  public async loadVoiceflowScript(): Promise<void> {
    if (this.isVoiceflowScriptLoaded) {
      return;
    }

    return new Promise<void>((resolve, reject) => {
      const v = document.createElement("script"),
        s = document.getElementsByTagName("script")[0];

      v.onload = () => {
        this.isVoiceflowScriptLoaded = true;
        resolve();
      };
      v.onerror = () => {
        reject();
      };
      v.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
      v.type = "text/javascript";
      s.parentNode?.insertBefore(v, s);
    });
  }

  public async loadVoiceChat(
    userInfo: UserInfo,
    config?: VoiceFlowConfig
  ): Promise<void> {
    const { userID, first_name, last_name } = userInfo;
    const { messages, defaultHidden, ...rest } = config ?? {};

    await window.voiceflow.chat.load({
      verify: { projectID: "657a6ffd384bd204ed1fa78b" },
      url: "https://general-runtime.voiceflow.com",
      versionID: "production",
      userID,
      launch: {
        event: {
          type: "launch",
          payload: {
            first_name: first_name,
            last_name: last_name,
          },
        },
      },
      ...rest,
    });

    if (defaultHidden) {
      window.voiceflow.chat.hide();
    }

    if (messages?.length) {
      window.voiceflow.chat.proactive.clear();
      window.voiceflow.chat.proactive.push(...messages);
    }

    this.isVoiceflowChatLoaded = true;
  }

  public async openVoiceFlowChat(userInfo: UserInfo): Promise<void> {
    if (!this.isVoiceFlowInitialized) {
      await this.initializeVoiceFlow(userInfo);
    }

    this.showVoiceflowChat();
    window.voiceflow?.chat?.open();
  }

  public destroyVoiceFlow() {
    this.isVoiceflowChatLoaded = false;
    window.voiceflow?.chat?.destroy();
  }

  public hideVoiceflowChat() {
    window.voiceflow?.chat?.hide();
  }

  public showVoiceflowChat() {
    window.voiceflow?.chat?.show();
  }

  public get isVoiceFlowInitialized(): boolean {
    return this.isVoiceflowScriptLoaded && this.isVoiceflowChatLoaded;
  }

  public async initializeVoiceFlow(
    userInfo: UserInfo,
    config?: VoiceFlowConfig
  ) {
    if (this.isVoiceFlowLoading) {
      // If already loading, return the ongoing initialization promise
      return this.initializationPromise;
    }

    this.isVoiceFlowLoading = true;
    this.initializationPromise = (async () => {
      try {
        await this.loadVoiceflowScript();
        await this.loadVoiceChat(userInfo, config);
      } finally {
        this.isVoiceFlowLoading = false;
        this.initializationPromise = null;
      }
    })();

    return this.initializationPromise;
  }
}
