import { Injectable } from "@angular/core";
import { DataAccessService } from "@kells/apis/data-access";
import {
  Portals,
  QueryParameters,
} from "apps/patient-reporting/src/app/shared/enums/query.enums";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(public readonly data: DataAccessService) {}

  isAetna$ = this.data.patientOrganizationsNames$.pipe(
    map((patientOrganizationsNames) =>
      patientOrganizationsNames.some(
        (name) => name?.toLowerCase() === Portals.Aetna.toLowerCase()
      )
    )
  );

  isDentalSavers$ = this.data.patientOrganizationsNames$.pipe(
    map((patientOrganizationsNames) =>
      patientOrganizationsNames.some(
        (name) => name?.toLowerCase() === Portals.DentalSavers.toLowerCase()
      )
    )
  );

  set localStoragePortal(portal: Portals | null) {
    localStorage.setItem(QueryParameters.Portal, portal || "");
  }

  get localStoragePortal(): Portals {
    return localStorage.getItem(QueryParameters.Portal) as Portals;
  }
}
