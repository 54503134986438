import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { HomeFooterComponent } from "./components/home-footer/home-footer.component";
import { SharedUiAngularModule } from "@kells/shared-ui/angular";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgLetModule, PushPipeModule } from "@ngrx-utils/store";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { HeaderComponent } from "./components/header/header.component";
import { EssentialPatientInfoModalComponent } from "./components/essential-patient-info-modal/essential-patient-info-modal.component";
import { OnboardingModalComponent } from "../home/components/onboarding-modal/onboarding-modal.component";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AppContainerComponent } from "./components/app-container/app-container.component";
import { RouterModule } from "@angular/router";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { PaymentSuccessModalComponent } from "./components/payment-success-modal/payment-success-modal.component";
import { DemoReportLinkComponent } from "../home/containers/demo-report-link/demo-report-link.component";
import { DateFormatDirective } from "./directives";

const materialModules = [
  MatButtonModule,
  MatIconModule,
  MatExpansionModule,
  MatListModule,
  MatCardModule,
  MatMenuModule,
  MatDialogModule,
  MatSnackBarModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatCheckboxModule,
];

const directives = [DateFormatDirective];

@NgModule({
  declarations: [
    DemoReportLinkComponent,
    HomeFooterComponent,
    HeaderComponent,
    EssentialPatientInfoModalComponent,
    PaymentSuccessModalComponent,
    OnboardingModalComponent,
    AppContainerComponent,
    ...directives,
  ],
  imports: [
    NgxIntlTelInputModule,
    RouterModule,
    CommonModule,
    SharedUiAngularModule,
    ReactiveFormsModule,
    FormsModule,
    NgLetModule,
    PushPipeModule,
    ...materialModules,
  ],
  exports: [
    HomeFooterComponent,
    HeaderComponent,
    DemoReportLinkComponent,
    ...materialModules,
    ...directives,
  ],
})
export class SharedModule {}
