<div class="container">
  <span class="label">{{ label }}</span>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="358"
    height="211"
    viewBox="-25 0 408 231"
    fill="none"
  >
    <g clip-path="url(#clip0_1_14)">
      <path
        d="M23 199C23 112.844 92.8436 43 179 43C265.156 43 335 112.844 335 199C335 205.075 339.925 210 346 210C352.075 210 357 205.075 357 199C357 100.693 277.307 21 179 21C80.6933 21 1 100.693 1 199C1 205.075 5.92487 210 12 210C18.0751 210 23 205.075 23 199Z"
        fill="#FDFDFD"
        stroke="#EBEBEB"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <g [attr.filter]="level === 'high' ? 'url(#filter0_d_2501_382)' : ''">
        <path
          d="M346 198C346 171.427 339.794 146.303 328.751 124C320.089 106.505 308.452 90.7446 294.5 77.3813"
          [attr.stroke]="highRiskColor"
          stroke-width="12"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>

      <g [attr.filter]="level === 'medium' ? 'url(#filter0_d_1_14)' : ''">
        <path
          d="M78.5 65.6136C88.3711 58.1645 99.0953 51.7858 110.5 46.65C131.402 37.2375 154.589 32 179 32C203.411 32 226.598 37.2375 247.5 46.65C258.905 51.7858 269.629 58.1645 279.5 65.6136"
          stroke="#FDD046"
          stroke-width="8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>

      <g [attr.filter]="level === 'low' ? 'url(#filter0_d_2501_380)' : ''">
        <path
          d="M12 199C12 172.427 18.2065 147.303 29.2489 125C37.9108 107.505 49.5483 91.7446 63.5 78.3813"
          [attr.stroke]="lowRiskColor"
          stroke-width="8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>

      <g filter="url(#filter1_d_1_14)" #riskIndicator>
        <path
          d="M12.5 210C20.5081 210 27 203.508 27 195.5C27 187.492 20.5081 181 12.5 181C4.49187 181 -2 187.492 -2 195.5C-2 203.508 4.49187 210 12.5 210Z"
          fill="white"
        />
        <path
          d="M12.5 206.5C18.5751 206.5 23.5 201.575 23.5 195.5C23.5 189.425 18.5751 184.5 12.5 184.5C6.42487 184.5 1.5 189.425 1.5 195.5C1.5 201.575 6.42487 206.5 12.5 206.5Z"
          stroke="#DEE2E6"
          stroke-width="7"
        />
        <path
          d="M12.5 206.5C18.5751 206.5 23.5 201.575 23.5 195.5C23.5 189.425 18.5751 184.5 12.5 184.5C6.42487 184.5 1.5 189.425 1.5 195.5C1.5 201.575 6.42487 206.5 12.5 206.5Z"
          [attr.stroke]="riskColor"
          stroke-width="7"
        />
      </g>
      <g filter="url(#filter2_d_1_14)">
        <path
          #riskIndicatorPath
          d="M12 203C19 -23 336 -26 346 203"
          stroke="#fff"
          stroke-opacity="0"
        />
      </g>
    </g>

    <defs>
      <!-- shadow for high level -->
      <filter
        *ngIf="level === 'high'"
        id="filter0_d_2501_382"
        x="270.5"
        y="33.3813"
        width="99.5"
        height="188"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="9" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          *ngIf="!reversed"
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.176245 0 0 0 0 0.104167 0 0 0 1 0"
        />
        <feColorMatrix
          *ngIf="reversed"
          type="matrix"
          values="0 0 0 0 0.517647 0 0 0 0 0.831373 0 0 0 0 0.266667 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2501_382"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2501_382"
          result="shape"
        />
      </filter>
      <!-- shadow for high level end -->

      <!-- shadow for medium level -->
      <filter
        *ngIf="level === 'medium'"
        id="filter0_d_1_14"
        x="56.4998"
        y="10"
        width="245"
        height="77.6138"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="9" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.992157 0 0 0 0 0.815686 0 0 0 0 0.27451 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1_14"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1_14"
          result="shape"
        />
      </filter>
      <!-- shadow for medium level end -->

      <!-- shadow for low level -->
      <filter
        *ngIf="level === 'low'"
        id="filter0_d_2501_380"
        x="0"
        y="39.3813"
        width="89.5"
        height="188"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="7" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          *ngIf="!reversed"
          type="matrix"
          values="0 0 0 0 0.517647 0 0 0 0 0.831373 0 0 0 0 0.266667 0 0 0 1 0"
        />
        <feColorMatrix
          *ngIf="reversed"
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.176245 0 0 0 0 0.104167 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2501_380"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2501_380"
          result="shape"
        />
      </filter>
      <!-- shadow for low level end -->

      <filter
        id="filter1_d_1_14"
        x="-20"
        y="163"
        width="65"
        height="65"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="9" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.195833 0 0 0 0 0.195833 0 0 0 0 0.195833 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1_14"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1_14"
          result="shape"
        />
      </filter>

      <filter
        id="filter2_d_1_14"
        x="7.50024"
        y="31.8732"
        width="342.999"
        height="179.149"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1_14"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1_14"
          result="shape"
        />
      </filter>

      <clipPath id="clip0_1_14">
        <rect width="408" height="241" fill="white" x="-25" y="-10" />
      </clipPath>
    </defs>
  </svg>
</div>
