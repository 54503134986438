<ng-container
  *ngLet="{
    images: images$ | async,
    isEmpty: isImagesEmpty$ | async
  } as xrayData"
>
  <ng-container *ngIf="!xrayData.isEmpty">
    <section class="xrays-cavity" id="xrays-cavity">
      <div class="section-content">
        <div>
          <div *ngIf="title" class="flex justify-between report-header mb-5">
            <h3 class="fw-700">{{ title }}</h3>
            <button
              *ngIf="showToggleXrayButton"
              class="border-blue-btn"
              (click)="toggleBtnValue(isShowXrays)"
            >
              {{ xrayBtnText }}
            </button>

            <koa-report-button
              *ngIf="downloadEnabled"
              [sm]="true"
              (click)="downloadXrays()"
              class="omit-report-generation"
            >
              <ng-container ngProjectAs="[icon]">
                <i class="fa fa-arrow-down-to-line" aria-hidden="true"></i>
              </ng-container>

              Download
            </koa-report-button>
          </div>

          <div *ngIf="isShowXrays">
            <div
              class="2xl:max-width-container xl:max-width-container xs:max-w-xs xs:ml-auto xs:mr-auto xs-overflow-auto"
            >
              <div class="cavity-wrapper">
                <ng-container
                  class="wrapper"
                  *ngFor="
                    let findingsByImage of xrayData.images;
                    let i = index;
                    trackBy: trackImageIdentityByUrl
                  "
                >
                  <ng-container
                    class="wrapper"
                    *ngIf="i >= cavityXrayStartIndex && i < cavityXrayEndIndex"
                  >
                    <div class="slide">
                      <div>
                        <koa-xray-image
                          [index]="i"
                          [showCaries]="showCaries"
                          [interactive]="interactive"
                          [showBoneloss]="showBoneloss"
                          [showPlaque]="showPlaque"
                          [showFracture]="showFracture"
                          [showGumRecession]="showGumRecession"
                          [showGumInflammation]="showGumInflammation"
                          [showMissingTooth]="showMissingTooth"
                          [showFindings]="showFindings"
                          [image]="findingsByImage.image"
                          [findingsByImage]="findingsByImage"
                          [totalCount]="imagesLength$ | async"
                          (onXrayOpen)="xrayOpenEvent.emit()"
                          [showFindingDescription]="showFindingDescription"
                        >
                        </koa-xray-image>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div>
              <koa-report-button
                *ngIf="downloadEnabled"
                [xs]="true"
                (click)="downloadXrays()"
                class="omit-report-generation"
              >
                <ng-container ngProjectAs="[icon]">
                  <i class="fa fa-arrow-down-to-line" aria-hidden="true"></i>
                </ng-container>

                Download
              </koa-report-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</ng-container>
