import { Component, Inject, OnInit } from "@angular/core";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AnalyticsService } from "@kells/apis/analytics";
import { PatientService } from "@kells/clinic-one/apis";
import { KellsPatient } from "@kells/interfaces/patient";
import { InfoCollectionOpenedEvent } from "apps/patient-reporting/src/app/shared/models/analytics/home-events";
import { format } from "date-fns";
import { PhoneNumberUtil } from "google-libphonenumber";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";
import { Country } from "ngx-intl-tel-input/lib/model/country.model";

const phoneUtil = PhoneNumberUtil.getInstance();

@Component({
  selector: "kpr-essential-patient-info-modal",
  templateUrl: "./essential-patient-info-modal.component.html",
  styleUrls: ["./essential-patient-info-modal.component.scss"],
})
export class EssentialPatientInfoModalComponent implements OnInit {
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  selectedCountry = CountryISO.UnitedStates;

  form: FormGroup = this.fb.group({
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    birthday: ["", Validators.required],
    gender: ["", Validators.required],
    phone: ["", [Validators.required]],
    state: ["", Validators.required],
    zipcode: ["", Validators.required],
    has_insurance: [false, Validators.required],
    consent_sms: [false, Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private patientService: PatientService,
    @Inject(MAT_DIALOG_DATA) public data: KellsPatient,
    public dialogRef: MatDialogRef<EssentialPatientInfoModalComponent>,
    private analyticsService: AnalyticsService
  ) {}

  onCountryChange(country: Country) {
    this.selectedCountry = country.iso2 as CountryISO;
  }

  ngOnInit(): void {
    this.analyticsService.record(InfoCollectionOpenedEvent({}));

    const phoneNumber =
      [this.data.phone_country_code, this.data.phone]
        .filter(Boolean)
        .join("") ?? "";
    if (this.data.phone_country_code) {
      this.setCountryFromPhoneNumber(phoneNumber);
    }

    this.form.patchValue({
      ...this.data,
      phone: phoneNumber,
      has_insurance: this.data.has_insurance ?? false,
      consent_sms: this.data.consent_sms ?? false,
      gender: this.data.gender === "unknown" ? "" : this.data.gender,
    });
  }

  private setCountryFromPhoneNumber(phoneNumber: string) {
    try {
      const parsedNumber = phoneUtil.parse(phoneNumber);
      const regionCode = phoneUtil.getRegionCodeForNumber(parsedNumber);

      if (regionCode) {
        this.selectedCountry = regionCode as CountryISO;
      }
    } catch (error) {
      console.error("Error parsing phone number:", error);
    }
  }

  private _updateValueAndValidity() {
    for (const controlName in this.form.controls) {
      this.form.controls[controlName].updateValueAndValidity();
    }
  }

  onSubmit() {
    this._updateValueAndValidity();
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    const data = {
      ...this.form.value,
      id: this.data.id,
      dob: format(new Date(this.form.value.birthday), "yyyy-MM-dd"),
      first_name: this.form.value.firstName,
      last_name: this.form.value.lastName,
      phone:
        this.form.value.phone?.e164Number
          ?.replace(this.form.value.phone?.dialCode ?? "", "")
          ?.replace(/\D+/g, "") ?? "",
      phone_country_code: this.form.value.phone?.dialCode ?? "",
      has_insurance: this.form.value.has_insurance ?? false,
      consent_sms: this.form.value.consent_sms ?? false,
    };

    this.patientService.updatePatient(data).subscribe(() => {
      this.dialogRef.close();
    });
  }
}
